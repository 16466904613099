import axios from '@/plugins/axios'
import { cleanObj } from '@/tools/helpers'

const state = () => ({
  amlItems: {
    page: 1,
    limit: 15,
    items: [],
  },
})

const getters = {
  amlItems: state => state.amlItems,
}

const actions = {
  fetchAmlAlerts({ commit }, requestQuery) {
    const params = cleanObj(requestQuery)

    return axios({ method: 'GET', url: '/aml/alerts', params }).then(response => {
      commit('setNewItems', response.data)

      return response
    })
  },
  fetchAmlAlertById(store, { id }) {
    return axios({ method: 'GET', url: `/aml/alerts/${id}` })
  },
  fetchAdmins() {
    return axios({ method: 'GET', url: '/aml/admins' })
  },
  setAdmin(store, { alertId, adminId }) {
    return axios({ method: 'POST', url: `/aml/alerts/${alertId}/assign`, data: { adminId } })
  },
  setAlertAprove(store, { id }) {
    return axios({ method: 'POST', url: `/aml/alerts/${id}/approve` })
  },
  setAlertDecline(store, { id, refund }) {
    return axios({ method: 'POST', url: `/aml/alerts/${id}/reject`, data: { refund } })
  },
}

const mutations = {
  setNewItems(state, payload) {
    state.amlItems = payload
  },
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
